<template>
  <aw-red-tabs :tabs="renderTabs">
    <template #default="tabData">
      <div
        v-if="tabData.content"
        :class="[$style.content, {
          [$style.contentWithTextAndButton]: tabData?.content?.contentText && tabData?.content?.button?.items?.length,
          [$style.contentWithOnlyText]: tabData?.content?.contentText && !tabData?.content?.button?.items?.length
        }]"
      >
        <div :class="$style.contentList">
          <template v-if="tabData.content.contentText">
            <aw-text-block :content="tabData.content" :class="$style.contentText" />
          </template>
          <template v-else>
            <div v-for="tab in tabData.content.tabs" :key="tab.contentId" :class="$style.contentListItem">
              <div :class="$style.contentListItemIcon">
                <img
                  v-if="tab?.media?.icon?.url"
                  :src="tab.media.icon.url"
                  :alt="tab.media.icon.alt"
                  aria-hidden="true"
                  :class="$style.contentListItemIconImage"
                  loading="lazy"
                >
                <lv-icon
                  v-else
                  name="picture-32"
                  :size="24"
                  :class="$style.contentListItemIconImageFallback"
                />
              </div>
              <lv-button
                v-if="tab?.link"
                :href="localePath(tab.link)"
                styling="link-primary-version2"
                shrink
                v-on="clickEventHandlers"
              >
                <span :class="$style.contentListItemLink">
                  {{ tab.infoText }}
                </span>
              </lv-button>
              <p v-else :class="$style.contentListItemText">
                {{ tab.infoText }}
              </p>
            </div>
          </template>
        </div>
        <div :class="$style.contentButtons">
          <aw-buttons
            :content="tabData.content"
            :button-attrs="{
              shrink: true,
              stretch: true,
            }"
            :show-title="false"
            :analytics-measurable="analyticsMeasurable"
            :analytics-banner-interaction="analyticsBannerInteraction"
          />
          <img
            :src="$addWebpPostfixIfSupported({ url: birdyImage, webpUrl: birdyImageWebp })"
            alt="birdyImage"
            :class="$style.contentButtonsBirdy"
            aria-hidden="true"
            loading="lazy"
          >
        </div>
      </div>
    </template>
  </aw-red-tabs>
</template>

<script setup>
  import { defineAsyncComponent } from 'vue';
  import birdyImage from '~~/common/assets/images/birdy.png';
  import birdyImageWebp from '~~/common/assets/images/birdy.webp';
  import analyticsBannerInteractionMixin, { genBannerInteractionClickEventHandlers } from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { LvIcon, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwRedTabs from '~~/common/components/Common/AwRedTabs/AwRedTabs.vue';
  const AwTextBlock = defineAsyncComponent(() => import('./AwTextBlock'));
  const AwButtons = defineAsyncComponent(() => import('./AwButtons'));

  const props = defineProps({
    ...analyticsBannerInteractionMixin.props,
    content: {
      type: Object,
      required: true,
    },
  });

  const renderTabs = computed(() => {
    return props.content.components.map(component => ({
      name: component?.infoText,
      contentId: component?.id,
      content: {
        tabs: component?.tabs,
        button: component?.button,
        contentText: component.contentText,
      },
      icon: component?.media?.icon?.url,
      iconAlt: component?.media?.icon?.alt,
    }));
  });

  const clickEventHandlers = computed(() => genBannerInteractionClickEventHandlers(props));
</script>

<style module lang="scss" rel="stylesheet/scss">
.content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  @include desktop-medium(min) {
    grid-template-columns: auto 218px;
  }

  &WithTextAndButton, &WithOnlyText {
    .contentText {
      @include tablet(min) {
        grid-column: span 4;
      }
    }
  }

  &WithOnlyText {
    gap: 0;
    @include desktop-medium(min) {
      grid-template-columns: 1fr;
    }

    .contentButtonsBirdy {
      display: none;
    }
  }

  &List {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    @include tablet(min) {
      grid-template-columns: repeat(4, 1fr);
      justify-content: space-between;
    }

    &Item {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-top: 12px;
      padding-bottom: 12px;
      border-top: 2px dashed $color-border-disabled;
      gap: 8px;

      &:first-child {
        padding-top: 0;
        border-top: none;
      }

      &:last-child {
        padding-bottom: 0;
      }

      @include tablet(min) {
        align-items: flex-start;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 20px;
        border-top: none;
        border-left: 1px dashed $color-border-disabled;

        &:first-child {
          padding-left: 0;
          border-left: none;
        }

        &:last-child {
          padding-right: 0;
        }
      }
      @include desktop-large(min) {
        padding-left: 40px;
      }

      &Icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        padding: 12px;
        border: 1px solid $color-dash-separator;
        border-radius: 16px;
        background-color: $color-background-1;

        &Image {
          width: 24px;
          height: 24px;

          &Fallback {
            color: $color-dash-separator;
          }
        }
      }

      &Text, &Link {
        font-family: $secondary-font;
        font-size: 14px;
        line-height: 20px;
        @include desktop-small(min) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &Text {
        font-weight: $font-weight-normal-v2;
      }
    }
  }

  &Buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 40px;
    @include desktop-medium(min) {
      align-items: flex-end;
      flex-direction: column-reverse;
    }

    &Birdy {
      width: 48px;
      height: 32px;
      @include tablet(min) {
        width: 62px;
        height: 40px;
      }
    }
  }
}
</style>
